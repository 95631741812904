var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c("label", { staticClass: "error" }, [
        _vm._v("\n\t" + _vm._s(_vm.error.msg) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }