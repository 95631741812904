var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "button",
    attrs: {
      type: "submit",
      disabled: _vm.working || _vm.disabled,
      "data-working": _vm.working,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }